var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"result-page"},[_c('Header'),_c('el-breadcrumb',{staticClass:"bread-crumb",attrs:{"separator":"/"}},[_c('el-breadcrumb-item',[_c('span',{on:{"click":function($event){return _vm.$router.push('/')}}},[_vm._v("首页")])]),_c('el-breadcrumb-item',[_c('span',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("练习与考试")])]),_c('el-breadcrumb-item',[_c('span',{staticStyle:{"color":"#333"}},[_vm._v(_vm._s(_vm.cTitle)+"解析")])])],1),_c('div',{staticClass:"result-paper"},[_c('ul',_vm._l((_vm.analysisResult),function(analysis,index){return _c('li',{key:analysis.id,ref:("item" + index),refInFor:true,staticClass:"result-item"},[_c('p',{staticClass:"item-title"},[_vm._v(" "+_vm._s(index + 1)+"、 "),_c('i',[_vm._v("（"+_vm._s(analysis.topic_type == 1 ? "单选题" : analysis.topic_type == 2 ? "多选题" : "判断题")+"）")]),_vm._v(_vm._s(analysis.question)+" ")]),(analysis.topic_type === 1)?_c('div',[_vm._l((JSON.parse(analysis.answer)),function(value,key,answer_index){return _c('ol',{key:answer_index,staticClass:"item-option"},[_c('li',[(
                  (analysis.user_answer == key &&
                    analysis.real_answer == key) ||
                  analysis.real_answer == key
                )?_c('img',{attrs:{"src":require("../assets/images/yes.png"),"alt":""}}):_vm._e(),(
                  analysis.user_answer != analysis.real_answer &&
                  analysis.user_answer == key
                )?_c('img',{attrs:{"src":require("../assets/images/no.png"),"alt":""}}):_vm._e(),(
                  analysis.user_answer != key && analysis.real_answer != key
                )?_c('b',[_vm._v(_vm._s(key))]):_vm._e(),_c('span',[_vm._v(_vm._s(value))])])])}),_c('p',{staticClass:"itm-answer"},[_vm._v(" 正确答案是"),_c('i',{staticClass:"answer-yes"},[_vm._v(" "+_vm._s(analysis.real_answer))]),_vm._v(", 你的答案是"),_c('i',{staticClass:"answer-no"},[_vm._v(" "+_vm._s(analysis.user_answer))])])],2):_vm._e(),(analysis.topic_type === 2)?_c('div',[_vm._l((JSON.parse(analysis.answer)),function(value,key,answer_index){return _c('ol',{key:answer_index,staticClass:"item-option"},[_c('li',[(
                  (analysis.user_answer.indexOf(key) != -1 &&
                    analysis.real_answer.indexOf(key) != -1) ||
                  analysis.real_answer.indexOf(key) != -1
                )?_c('img',{attrs:{"src":require("../assets/images/yes.png"),"alt":""}}):_vm._e(),(
                  analysis.user_answer.indexOf(key) != -1 &&
                  analysis.real_answer.indexOf(key) == -1
                )?_c('img',{attrs:{"src":require("../assets/images/no.png"),"alt":""}}):_vm._e(),(
                  analysis.user_answer.indexOf(key) == -1 &&
                  analysis.real_answer.indexOf(key) == -1
                )?_c('b',[_vm._v(_vm._s(key))]):_vm._e(),_c('span',[_vm._v(_vm._s(value))])])])}),_c('p',{staticClass:"itm-answer"},[_vm._v(" 正确答案是"),_c('i',{staticClass:"answer-yes"},[_vm._v(" "+_vm._s(analysis.real_answer))]),_vm._v(", 你的答案是"),_c('i',{staticClass:"answer-no"},[_vm._v(" "+_vm._s(analysis.user_answer))])])],2):_vm._e(),(analysis.topic_type === 3)?_c('div',[_vm._l((JSON.parse(analysis.answer)),function(value,key,answer_index){return _c('ol',{key:answer_index,staticClass:"item-option"},[_c('li',[(
                  analysis.user_answer == value &&
                  analysis.real_answer == value
                )?_c('img',{attrs:{"src":require("../assets/images/yes.png"),"alt":""}}):_vm._e(),(
                  analysis.user_answer != analysis.real_answer &&
                  analysis.user_answer == value
                )?_c('img',{attrs:{"src":require("../assets/images/no.png"),"alt":""}}):_vm._e(),(
                  (analysis.user_answer != value &&
                    analysis.real_answer != value) ||
                  (analysis.user_answer != analysis.real_answer &&
                    analysis.user_answer != value)
                )?_c('b',{staticClass:"judge-item"}):_vm._e(),_c('span',[_vm._v(_vm._s(value))])])])}),_c('p',{staticClass:"itm-answer"},[_vm._v(" 正确答案是"),_c('i',{staticClass:"answer-yes"},[_vm._v(" "+_vm._s(analysis.real_answer))]),_vm._v(", 你的答案是"),_c('i',{staticClass:"answer-no"},[_vm._v(" "+_vm._s(analysis.user_answer))])])],2):_vm._e()])}),0),_c('ExamCard',{attrs:{"analysisCard":_vm.analysisCard,"analysisResult":_vm.analysisResult},on:{"toAnswer":_vm.toAnswer}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }